@use 'legacy/common/styles/colors.scss';

.forgotPassword {
  margin-bottom: 20px;
}

.actionItem {
  margin: 20px 0;
  color: colors.$primary;
}

.link {
  color: colors.$primary;
}

.illustration {
  display: flex;
  justify-content: center;
}
