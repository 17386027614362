@use 'legacy/common/styles/config.scss';

.wrapper {
  width: 100%;
  height: 100%;

  padding: 40px;
  margin: 0 auto;

  text-align: center;

  background: white;

  @media (min-width: config.$md) {
    height: auto;
    width: 400px;

    border-radius: 20px;

    padding: 40px 55px 20px 55px;

    background: white;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
  }

  @media (min-width: config.$xll) {
    width: 430px;
    padding: 65px 65px 45px;
  }
}

.modalWrapper {
  @media (min-width: config.$md) {
    box-shadow: none;
  }

  @media (min-width: config.$xll) {
    width: auto;
  }
}

.logo {
  display: flex;
  justify-content: center;

  img {
    height: 25px;
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
}

.subtitle {
  margin-top: 10px;
  font-weight: 400;
}
