.buttonGroup {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .optionButton {
    margin: 0 6px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 16px;
    cursor: pointer;

    .apple {
      height: 100%;
      width: 100%;
      background-color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fb {
      height: 100%;
      width: 100%;
      background-color: #4267b2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .phone {
      background: #6586ff;
      color: white;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }
}
