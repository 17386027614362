.fadeIn {
  animation: fade-in 800ms ease;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.slideIn {
  animation: slide-in 250ms ease;
}

@keyframes slide-in {
  0% {
    transform: translateY(-60px);
  }

  100% {
    transform: translateY(0px);
  }
}
